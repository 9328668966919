import horizontal_3 from '../assets/images/horizontal-8.JPG'
import video from '../assets/images/CurtainsVideo.MP4'
import returnimg from '../assets/images/return.svg'
import support from '../assets/images/support.svg'
import bag from '../assets/images/bag.svg'
import user from '../assets/images/user.svg'
import truck from '../assets/images/truck.svg'
import vertical_4 from '../assets/images/vertical-4.JPG'
const about = () => {
    return (
        <>
            <div class="hero" style={{ backgroundImage: `url(${horizontal_3})`, backgroundRepeat: 'round' }}>
                <div class="container">
                    <div class="row justify-content-between">
                        <div class="col-lg-5">
                            <div class="intro-excerpt">
                                <h1>About Us</h1>
                                <p class="mb-4">Donec vitae odio quis nisl dapibus malesuada. Nullam ac aliquet velit. Aliquam vulputate velit imperdiet dolor tempor tristique.</p>
                                <p><a href="" class="btn btn-secondary me-2">Shop Now</a><a href="#" class="btn btn-white-outline">Explore</a></p>
                            </div>
                        </div>
                        <div class="col-lg-7">
                            <div class="hero-img-wrap">

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="why-choose-section">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-lg-6">
                            <h2 className="section-title">Why Choose Us</h2>
                            <p>Donec vitae odio quis nisl dapibus malesuada. Nullam ac aliquet velit. Aliquam vulputate velit imperdiet dolor tempor tristique.</p>

                            <div className="row my-5">
                                <div className="col-6 col-md-6">
                                    <div className="feature">
                                        <div className="icon">
                                            <img src={bag} alt="Image" className="imf-fluid" />
                                        </div>
                                        <h3>Customized Solutions </h3>
                                        <p>We create custom window treatments that perfectly match your unique style and functional requirements.</p>
                                    </div>
                                </div>

                                <div className="col-6 col-md-6">
                                    <div className="feature">
                                        <div className="icon">
                                            <img src={bag} alt="Image" className="imf-fluid" />
                                        </div>
                                        <h3>High-Quality Materials </h3>
                                        <p>We use only the highest quality materials to create our custom window treatments. We offer a wide range of fabric options, hardware materials, and other accessories to ensure that you can create a finished product that perfectly matches your decor style</p>
                                    </div>
                                </div>

                                <div className="col-6 col-md-6">
                                    <div className="feature">
                                        <div className="icon">
                                            <img src={user} alt="Image" className="imf-fluid" />
                                        </div>
                                        <h3>Expert Tailors</h3>
                                        <p>Our team of expert tailors Will take pride in our attention to detail and quality craftsmanship, ensuring that every project is completed to your satisfaction.</p>
                                    </div>
                                </div>

                                <div className="col-6 col-md-6">
                                    <div className="feature">
                                        <div className="icon">
                                            <img src={returnimg} alt="Image" className="imf-fluid" />
                                        </div>
                                        <h3>Hassle-Free Installation</h3>
                                        <p>Our Dedicated team will come to your home to install your custom window treatments, providing you with a hassle-free installation experience.</p>
                                    </div>
                                </div>
                                <div className="col-6 col-md-6">
                                    <div className="feature">
                                        <div className="icon">
                                            <img src={returnimg} alt="Image" className="imf-fluid" />
                                        </div>
                                        <h3>Competitive Pricing </h3>
                                        <p>We offer competitive pricing for our custom window treatments, ensuring that you get the best value for your money</p>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="col-lg-5">
                            <div className="img-wrap">


                                <img src={vertical_4} alt="Image" className="img-fluid" />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default about;