
import vertical_1 from '../assets/images/vertical-1.JPG'
import vertical_2 from '../assets/images/vertical-2.JPG'
import vertical_3 from '../assets/images/vertical-3.JPG'

import vertical_5 from '../assets/images/vertical-5.JPG'
import vertical_6 from '../assets/images/vertical-6.JPG'
import mainlanding from '../assets/images/mainlanding.JPG'
import horizontal_1 from '../assets/images/horizontal-1.JPG'
import horizontal_2 from '../assets/images/horizontal-2.JPG'
import horizontal_3 from '../assets/images/horizontal-3.JPG'
import video from '../assets/images/CurtainsVideo.MP4'
import returnimg from '../assets/images/return.svg'
import support from '../assets/images/support.svg'
import bag from '../assets/images/bag.svg'
import truck from '../assets/images/truck.svg'
import cart from '../assets/images/cart.svg'
import cross from '../assets/images/cross.svg'
import post1 from '../assets/images/post-1.jpg'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
const Index = () => {
	return (
		<>
			<Swiper navigation={true} autoplay={{
				delay: 2500,
				disableOnInteraction: false,
			}} modules={[Navigation, Autoplay]} className="mySwiper">
				<SwiperSlide>
					<div className="hero" style={{ backgroundImage: `url(${mainlanding})`, backgroundRepeat: 'round' }}>
						<div className="container">
							<div className="row justify-content-between">
								<div className="col-lg-5">
									<div className="intro-excerpt">
										<h1>Modern Interior <span clsas="d-block">Design Studio</span></h1>
										<p className="mb-4">Donec vitae odio quis nisl dapibus malesuada. Nullam ac aliquet velit. Aliquam vulputate velit imperdiet dolor tempor tristique.</p>
										<p><a href="" className="btn btn-secondary me-2">Shop Now</a><a href="#" className="btn btn-white-outline">Explore</a></p>
									</div>
								</div>
								<div className="col-lg-7">
									<div className="hero-img-wrap">
										{/* <img src="../public/assets/images/couch.png" className="img-fluid"/> */}
									</div>
								</div>
							</div>
						</div>
					</div>
				</SwiperSlide>
				<SwiperSlide><div className="hero" style={{ backgroundImage: `url(${horizontal_1})`, backgroundRepeat: 'round' }}>
					<div className="container">
						<div className="row justify-content-between">
							<div className="col-lg-5">
								<div className="intro-excerpt">
									<h1>Modern Interior <span clsas="d-block">Design Studio</span></h1>
									<p className="mb-4">Donec vitae odio quis nisl dapibus malesuada. Nullam ac aliquet velit. Aliquam vulputate velit imperdiet dolor tempor tristique.</p>
									<p><a href="" className="btn btn-secondary me-2">Shop Now</a><a href="#" className="btn btn-white-outline">Explore</a></p>
								</div>
							</div>
							<div className="col-lg-7">
								<div className="hero-img-wrap">
									{/* <img src="../public/assets/images/couch.png" className="img-fluid" /> */}
								</div>
							</div>
						</div>
					</div>
				</div></SwiperSlide>
				<SwiperSlide><div className="hero" style={{ backgroundImage: `url(${horizontal_2})`, backgroundRepeat: 'round' }}>
					<div className="container">
						<div className="row justify-content-between">
							<div className="col-lg-5">
								<div className="intro-excerpt">
									<h1>Modern Interior <span clsas="d-block">Design Studio</span></h1>
									<p className="mb-4">Donec vitae odio quis nisl dapibus malesuada. Nullam ac aliquet velit. Aliquam vulputate velit imperdiet dolor tempor tristique.</p>
									<p><a href="" className="btn btn-secondary me-2">Shop Now</a><a href="#" className="btn btn-white-outline">Explore</a></p>
								</div>
							</div>
							<div className="col-lg-7">
								<div className="hero-img-wrap">
									{/* <img src="../public/assets/images/couch.png" className="img-fluid" /> */}
								</div>
							</div>
						</div>
					</div>
				</div></SwiperSlide>
			</Swiper>


			<div className="product-section">
				<div className="container">
					<div className="row">


						<div className="col-md-12 col-lg-3 mb-5 mb-lg-0">
							<h2 className="mb-4 section-title">Crafted with excellent material.</h2>
							<p className="mb-4">Donec vitae odio quis nisl dapibus malesuada. Nullam ac aliquet velit. Aliquam vulputate velit imperdiet dolor tempor tristique. </p>
							<p><a href="shop.html" className="btn">Explore More</a></p>
						</div>

						<div className="col-12 col-md-4 col-lg-3 mb-5 mb-md-0">
							<a className="product-item" href="#">
								<img src={vertical_1} className="img-fluid product-thumbnail" />
								<h3 className="product-title">Nordic Curtain</h3>
								{/* <strong className="product-price">$50.00</strong> */}

								{/* <span className="icon-cross">
								<img src={cross} className="img-fluid"/>
							</span> */}
							</a>
						</div>

						<div className="col-12 col-md-4 col-lg-3 mb-5 mb-md-0">
							<a className="product-item" href="#">
								<img src={vertical_2} className="img-fluid product-thumbnail" />
								<h3 className="product-title">Kruzo Aero Curtain</h3>
								{/* <strong className="product-price">$78.00</strong> */}

								{/* <span className="icon-cross">
								<img src={cross} className="img-fluid"/>
							</span> */}
							</a>
						</div>

						<div className="col-12 col-md-4 col-lg-3 mb-5 mb-md-0">
							<a className="product-item" href="#">
								<img src={vertical_3} className="img-fluid product-thumbnail" />
								<h3 className="product-title">Ergonomic Curtain</h3>
								{/* <strong className="product-price">$43.00</strong> */}
								{/* <span className="icon-cross">
								<img src={cross} className="img-fluid"/>
							</span> */}
							</a>
						</div>

					</div>
				</div>
			</div>

			<div className="why-choose-section">
				<div className="container">
					<div className="row justify-content-between">
						<div className="col-lg-6">
							<h2 className="section-title">Why Choose Us</h2>
							<p>Donec vitae odio quis nisl dapibus malesuada. Nullam ac aliquet velit. Aliquam vulputate velit imperdiet dolor tempor tristique.</p>

							<div className="row my-5">
								<div className="col-6 col-md-6">
									<div className="feature">
										<div className="icon">
											<img src={truck} alt="Image" className="imf-fluid" />
										</div>
										<h3>Fast &amp; Free Shipping</h3>
										<p>Donec vitae odio quis nisl dapibus malesuada. Nullam ac aliquet velit. Aliquam vulputate.</p>
									</div>
								</div>

								<div className="col-6 col-md-6">
									<div className="feature">
										<div className="icon">
											<img src={bag} alt="Image" className="imf-fluid" />
										</div>
										<h3>Easy to Shop</h3>
										<p>Donec vitae odio quis nisl dapibus malesuada. Nullam ac aliquet velit. Aliquam vulputate.</p>
									</div>
								</div>

								<div className="col-6 col-md-6">
									<div className="feature">
										<div className="icon">
											<img src={support} alt="Image" className="imf-fluid" />
										</div>
										<h3>24/7 Support</h3>
										<p>Donec vitae odio quis nisl dapibus malesuada. Nullam ac aliquet velit. Aliquam vulputate.</p>
									</div>
								</div>

								<div className="col-6 col-md-6">
									<div className="feature">
										<div className="icon">
											<img src={returnimg} alt="Image" className="imf-fluid" />
										</div>
										<h3>Hassle Free Returns</h3>
										<p>Donec vitae odio quis nisl dapibus malesuada. Nullam ac aliquet velit. Aliquam vulputate.</p>
									</div>
								</div>

							</div>
						</div>

						<div className="col-lg-5">
							<div className="img-wrap">

								<video controls autoplay>
									<source src={video} type="video/mp4" />
								</video>
								{/* <img src={vertical_4} alt="Image" className="img-fluid" /> */}
							</div>
						</div>

					</div>
				</div>
			</div>

			<div className="we-help-section">
				<div className="container">
					<div className="row justify-content-between">
						<div className="col-lg-7 mb-5 mb-lg-0">
							<div className="imgs-grid">
								<div className="grid grid-1"><img src={vertical_5} alt="Untree.co" /></div>
								<div className="grid grid-2"><img src={post1} alt="Untree.co" /></div>
								<div className="grid grid-3"><img src={vertical_6} alt="Untree.co" /></div>
							</div>
						</div>
						<div className="col-lg-5 ps-lg-5">
							<h2 className="section-title mb-4">We Help You Make Modern Interior Design</h2>
							<p>Donec facilisis quam ut purus rutrum lobortis. Donec vitae odio quis nisl dapibus malesuada. Nullam ac aliquet velit. Aliquam vulputate velit imperdiet dolor tempor tristique. Pellentesque habitant morbi tristique senectus et netus et malesuada</p>

							<ul className="list-unstyled custom-list my-4">
								<li>Donec vitae odio quis nisl dapibus malesuada</li>
								<li>Donec vitae odio quis nisl dapibus malesuada</li>
								<li>Donec vitae odio quis nisl dapibus malesuada</li>
								<li>Donec vitae odio quis nisl dapibus malesuada</li>
							</ul>
							<p><a herf="#" className="btn">Explore</a></p>
						</div>
					</div>
				</div>
			</div>

		</>
	)
}
export default Index;