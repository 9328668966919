import Modal from 'react-modal';
import { useState } from 'react'

import vertical_1 from '../assets/images/vertical-1.JPG'
import vertical_2 from '../assets/images/vertical-2.JPG'
import vertical_3 from '../assets/images/vertical-3.JPG'
import vertical_4 from '../assets/images/vertical-4.JPG'
import vertical_5 from '../assets/images/vertical-5.JPG'
import vertical_6 from '../assets/images/vertical-6.JPG'
import horizontal_1 from '../assets/images/horizontal-1.JPG'
import horizontal_2 from '../assets/images/horizontal-2.JPG'
import horizontal_3 from '../assets/images/horizontal-3.JPG'
import horizontal_4 from '../assets/images/horizontal-4.JPG'
import horizontal_5 from '../assets/images/horizontal-5.JPG'
import horizontal_6 from '../assets/images/horizontal-6.JPG'
import horizontal_7 from '../assets/images/horizontal-7.JPG'
import horizontal_8 from '../assets/images/horizontal-8.JPG'
import horizontal_9 from '../assets/images/horizontal-9.JPG'
import horizontal_10 from '../assets/images/horizontal-10.JPG'
import horizontal_11 from '../assets/images/horizontal-11.JPG'
const Gallery = () => {

	const [modalIsOpen, setIsOpen] = useState(false);
	const [imgURL, setImgUrl] = useState('')
	const openModal = () => {
		setIsOpen(true);
	}



	const closeModal = () => {
		setIsOpen(false);
	}
	return (<section className="blog-pagev2-area mr_shop">
		<div className="container">
			<div className="row">

				<div className="content-side col-xs-12 col-sm-12 col-md-12 ">
					<div className="our-shop">
						<div className="row clearfix">




							<div className="col-lg-3 col-md-12">
								<div className="product-block-two">
									<div className="inner-box"><div className="image">
										<img fetchpriority="high" width="263" height="300" src={vertical_1}
											className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail" alt="" decoding="async" />
										<div className="overlay">
											<div className="shop_metas">

												<a className="lightbox-image shop_image" onClick={() => {
													openModal();
													setImgUrl(vertical_1)
												}} href="#" data-group="1"><span className="fa fa-search"></span></a>
											</div>

										</div>
									</div>

									</div>

								</div>

							</div>
							<div className="col-lg-3 col-md-12">
								<div className="product-block-two">
									<div className="inner-box"><div className="image">
										<img width="262" height="300" src={vertical_2} className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail" alt="" decoding="async" />
										<div className="overlay"><div className="shop_metas">

											<a className="lightbox-image shop_image"
												onClick={() => {
													openModal();
													setImgUrl(vertical_2)
												}} href="#" data-group="1"><span className="fa fa-search"></span></a>
										</div>

										</div>
									</div>

									</div>

								</div>
							</div>


							<div className="col-lg-3 col-md-12">
								<div className="product-block-two">
									<div className="inner-box"><div className="image">
										<img width="263" height="300" src={horizontal_1} className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail" alt="" decoding="async" />
										<div className="overlay">
											<div className="shop_metas">

												<a className="lightbox-image shop_image"
													onClick={() => {
														openModal();
														setImgUrl(horizontal_1)
													}}
													href="#" data-group="1"><span className="fa fa-search"></span></a>
											</div>

										</div>
									</div>

									</div>
								</div>

							</div>


							<div className="col-lg-3 col-md-12">
								<div className="product-block-two">
									<div className="inner-box"><div className="image">
										<img loading="lazy" width="262" height="300" src={vertical_3} className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail" alt="" decoding="async" />				<div className="overlay">
											<div className="shop_metas">

												<a className="lightbox-image shop_image"
													onClick={() => {
														openModal();
														setImgUrl(vertical_3)
													}} href="#" data-group="1"><span className="fa fa-search"></span></a>
											</div>

										</div>
									</div>

									</div>
								</div>

							</div>


							<div className="col-lg-3 col-md-12">
								<div className="product-block-two">
									<div className="inner-box"><div className="image">
										<img loading="lazy" width="300" height="300" src={horizontal_2} />				<div className="overlay">
											<div className="shop_metas">
												<a className="lightbox-image shop_image"
													onClick={() => {
														openModal();
														setImgUrl(horizontal_2)
													}} href="#" data-group="1"><span className="fa fa-search"></span></a>
											</div>

										</div>
									</div>

									</div>
								</div>

							</div>


							<div className="col-lg-3 col-md-12">
								<div className="product-block-two">
									<div className="inner-box"><div className="image">
										<img loading="lazy" width="300" height="300" src={vertical_4} className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail" alt="" decoding="async" />
										<div className="overlay">
											<div className="shop_metas">
												<a className="lightbox-image shop_image"
													onClick={() => {
														openModal();
														setImgUrl(vertical_4)
													}} href="#" data-group="1"><span className="fa fa-search"></span></a>
											</div>

										</div>
									</div>

									</div>
								</div>

							</div>


							<div className="col-lg-3 col-md-12">
								<div className="product-block-two">
									<div className="inner-box"><div className="image">
										<img loading="lazy" width="300" height="300" src={vertical_5} />
										<div className="overlay">
											<div className="shop_metas"><a className="lightbox-image shop_image"
												onClick={() => {
													openModal();
													setImgUrl(vertical_5)
												}}
												href="#" data-group="1"><span className="fa fa-search"></span></a>
											</div>

										</div>
									</div>

									</div>
								</div>

							</div>


							<div className="col-lg-3 col-md-12">
								<div className="product-block-two">
									<div className="inner-box"><div className="image">
										<img loading="lazy" width="300" height="300" src={vertical_6} className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail" alt="" decoding="async" />				<div className="overlay">
											<div className="shop_metas">
												<a className="lightbox-image shop_image"
													onClick={() => {
														openModal();
														setImgUrl(vertical_6)
													}} href='#' data-group="1"><span className="fa fa-search"></span></a>
											</div>

										</div>
									</div>

									</div>
								</div>

							</div>


							<div className="col-lg-3 col-md-12">
								<div className="product-block-two">
									<div className="inner-box"><div className="image"><img loading="lazy" width="300" height="300" src={horizontal_3} className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail" alt="" decoding="async"
									/>
										<div className="overlay">
											<div className="shop_metas"><a className="lightbox-image shop_image"
												onClick={() => {
													openModal();
													setImgUrl(horizontal_3)
												}} href="#" data-group="1"><span className="fa fa-search"></span></a>
											</div>

										</div>
									</div>

									</div>
								</div>

							</div>


							<div className="col-lg-3 col-md-12">
								<div className="product-block-two">
									<div className="inner-box"><div className="image"><img loading="lazy" width="300" height="300" src={horizontal_4} />
										<div className="overlay">
											<div className="shop_metas"><a className="lightbox-image shop_image"
												onClick={() => {
													openModal();
													setImgUrl(horizontal_4)
												}}
												href="#" data-group="1"><span className="fa fa-search"></span></a>
											</div>

										</div>
									</div>

									</div>
								</div>

							</div>


							<div className="col-lg-3 col-md-12">
								<div className="product-block-two">
									<div className="inner-box"><div className="image"><img loading="lazy" width="300" height="300" src={horizontal_5} className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail" alt="" decoding="async"
									/>
										<div className="overlay">
											<div className="shop_metas"><a className="lightbox-image shop_image"
												onClick={() => {
													openModal();
													setImgUrl(horizontal_5)
												}} href="#" data-group="1"><span className="fa fa-search"></span></a>
											</div>

										</div>
									</div>

									</div>
								</div>

							</div>


							<div className="col-lg-3 col-md-12">
								<div className="product-block-two">
									<div className="inner-box"><div className="image"><img loading="lazy" width="300" height="300" src={horizontal_6} className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail" alt="" decoding="async" />
										<div className="overlay">
											<div className="shop_metas"><a className="lightbox-image shop_image"
												onClick={() => {
													openModal();
													setImgUrl(horizontal_6)
												}} href="#" data-group="1"><span className="fa fa-search"></span></a>
											</div>

										</div>
									</div>

									</div>
								</div>

							</div>
							<div className="col-lg-3 col-md-12">
								<div className="product-block-two">
									<div className="inner-box"><div className="image"><img loading="lazy" width="300" height="300" src={horizontal_7} className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail" alt="" decoding="async" />
										<div className="overlay">
											<div className="shop_metas"><a className="lightbox-image shop_image"
												onClick={() => {
													openModal();
													setImgUrl(horizontal_7)
												}} href="#" data-group="1"><span className="fa fa-search"></span></a>
											</div>

										</div>
									</div>

									</div>
								</div>

							</div>
							<div className="col-lg-3 col-md-12">
								<div className="product-block-two">
									<div className="inner-box"><div className="image"><img loading="lazy" width="300" height="300" src={horizontal_8} className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail" alt="" decoding="async" />
										<div className="overlay">
											<div className="shop_metas"><a className="lightbox-image shop_image"
												onClick={() => {
													openModal();
													setImgUrl(horizontal_8)
												}} href="#" data-group="1"><span className="fa fa-search"></span></a>
											</div>

										</div>
									</div>

									</div>
								</div>

							</div>
							<div className="col-lg-3 col-md-12">
								<div className="product-block-two">
									<div className="inner-box"><div className="image"><img loading="lazy" width="300" height="300" src={horizontal_9} className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail" alt="" decoding="async" />
										<div className="overlay">
											<div className="shop_metas"><a className="lightbox-image shop_image"
												onClick={() => {
													openModal();
													setImgUrl(horizontal_9)
												}} href="#" data-group="1"><span className="fa fa-search"></span></a>
											</div>

										</div>
									</div>

									</div>
								</div>

							</div>
							<div className="col-lg-3 col-md-12">
								<div className="product-block-two">
									<div className="inner-box"><div className="image"><img loading="lazy" width="300" height="300" src={horizontal_10} className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail" alt="" decoding="async" />
										<div className="overlay">
											<div className="shop_metas"><a className="lightbox-image shop_image"
												onClick={() => {
													openModal();
													setImgUrl(horizontal_10)
												}} href="#" data-group="1"><span className="fa fa-search"></span></a>
											</div>

										</div>
									</div>

									</div>
								</div>

							</div>
							<div className="col-lg-3 col-md-12">
								<div className="product-block-two">
									<div className="inner-box"><div className="image"><img loading="lazy" width="300" height="300" src={horizontal_11} className="attachment-woocommerce_thumbnail size-woocommerce_thumbnail" alt="" decoding="async" />
										<div className="overlay">
											<div className="shop_metas"><a className="lightbox-image shop_image"
												onClick={() => {
													openModal();
													setImgUrl(horizontal_11)
												}} href="#" data-group="1"><span className="fa fa-search"></span></a>
											</div>

										</div>
									</div>

									</div>
								</div>

							</div>


						</div>




					</div>
				</div>
			</div>


		</div>

		<Modal
			isOpen={modalIsOpen}
			onRequestClose={closeModal}
			shouldCloseOnOverlayClick={true}
			style={{ maxWidth: '500px', textAlign: 'center' }}
		>
			<header >
				<span onClick={closeModal} class="close-button topright">X</span>
			</header>

			<img src={imgURL} />



		</Modal>
	</section>)
}

export default Gallery;