import logo from './logo.svg';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import './assets/styles/css/bootstrap.min.css'
import './assets/styles/css/style.css'
import './assets/styles/css/tiny-slider.css'
import Layout from './pages/layout'
import Index from './pages/index'
import Thankyou from './pages/thankyou'
import About from './pages/about'
import Contact from './pages/contact'
import Gallery from './pages/gallery'

function App() {
  return (

  <Router>
    <Routes>
      <Route path="/" element={<Layout />}>
      <Route index element={<Index />}/>
      <Route path='contact-us' element={<Contact />}/>
      <Route path='about-us' element={<About />}/>
      <Route path='gallery' element={<Gallery />}/>
      <Route path='thankyou' element={<Thankyou />}/>
      </Route>
    </Routes>
  </Router>
  
 
  );
}

export default App;
