import { Outlet, useLocation } from 'react-router-dom';


const Layout = () => {
	const currentPath = useLocation().pathname;
	console.log(currentPath)
	return (
		<div>


			<nav className="custom-navbar navbar navbar navbar-expand-md navbar-dark bg-dark" arial-label="Furni navigation bar">

				<div className="container">
					<a className="navbar-brand" href="/">Curtain Gallery<span></span></a>

					<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsFurni" aria-controls="navbarsFurni" aria-expanded="false" aria-label="Toggle navigation">
						<span className="navbar-toggler-icon"></span>
					</button>

					<div className="collapse navbar-collapse" id="navbarsFurni">
						<ul className="custom-navbar-nav navbar-nav ms-auto mb-2 mb-md-0">
							<li className={currentPath == '/' ? "nav-item active" : "nav-item"}>
								<a className="nav-link" href="/">Home</a>
							</li>
							{/* <li><a className="nav-link" href="shop.html">Shop</a></li> */}
							<li className={currentPath == '/about-us' ? "nav-item active" : "nav-item"}><a className="nav-link" href="/about-us">About us</a></li>
							<li className={currentPath == '/gallery' ? "nav-item active" : "nav-item"}><a className="nav-link" href="/gallery">Gallery</a></li>
							<li className={currentPath == '/contact-us' ? "nav-item active" : "nav-item"}><a className="nav-link" href="/contact-us">Contact us</a></li>
						</ul>

						{/* <ul className="custom-navbar-cta navbar-nav mb-2 mb-md-0 ms-5">
						<li><a className="nav-link" href="#"><img src="images/user.svg"/></a></li>
						<li><a className="nav-link" href="cart.html"><img src="images/cart.svg"/></a></li>
					</ul> */}
					</div>
				</div>

			</nav>

			<Outlet />

			<footer className="footer-section">
				<div className="container relative">

					{/* <div className="sofa-img">
					<img src={curtainimage} alt="Image" className="img-fluid"/>
				</div> */}

					{/* <div className="row">
					<div className="col-lg-8">
						<div className="subscription-form">
							<h3 className="d-flex align-items-center"><span className="me-1"><img src="images/envelope-outline.svg" alt="Image" className="img-fluid"/></span><span>Subscribe to Newsletter</span></h3>

							<form action="#" className="row g-3">
								<div className="col-auto">
									<input type="text" className="form-control" placeholder="Enter your name"/>
								</div>
								<div className="col-auto">
									<input type="email" className="form-control" placeholder="Enter your email"/>
								</div>
								<div className="col-auto">
									<button className="btn btn-primary">
										<span className="fa fa-paper-plane"></span>
									</button>
								</div>
							</form>

						</div>
					</div>
				</div> */}

					<div className="row g-5 mb-5">
						<div className="col-lg-12">
							<div className="mb-4 footer-logo-wrap"><a href="#" className="footer-logo">Curtain Gallery<span></span></a></div>
							<iframe style={{ width: '100%' }} height="350" src="http://maps.google.com/maps?q=26.904957554047524,75.74895298684825&z=15&output=embed"></iframe>


						</div>

						{/* <div className="col-lg-8">
							<div className="row links-wrap">
								<div className="col-6 col-sm-6 col-md-3">
									<ul className="list-unstyled">
										<li><a href="#">About us</a></li>
										<li><a href="#">Services</a></li>
										<li><a href="#">Blog</a></li>
										<li><a href="#">Contact us</a></li>
									</ul>
								</div>

								<div className="col-6 col-sm-6 col-md-3">
									<ul className="list-unstyled">
										<li><a href="#">Support</a></li>
										<li><a href="#">Knowledge base</a></li>
										<li><a href="#">Live chat</a></li>
									</ul>
								</div>

								<div className="col-6 col-sm-6 col-md-3">
									<ul className="list-unstyled">
										<li><a href="#">Jobs</a></li>
										<li><a href="#">Our team</a></li>
										<li><a href="#">Leadership</a></li>
										<li><a href="#">Privacy Policy</a></li>
									</ul>
								</div>


							</div>
						</div> */}

					</div>

					<div className="border-top copyright">
						<div className="row pt-4">
							<div className="col-lg-6">
								<p className="mb-2 text-center text-lg-start">Copyright &copy;<script>document.write(new Date().getFullYear());</script>. All Rights Reserved.
								</p>
							</div>

							<div className="col-lg-6 text-center text-lg-end">
								<ul className="list-unstyled custom-social">
									<li><a href="#"><span className="fa fa-brands fa-facebook-f"></span></a></li>
									<li><a href="#"><span className="fa fa-brands fa-twitter"></span></a></li>
									<li><a href="#"><span className="fa fa-brands fa-instagram"></span></a></li>
									<li><a href="#"><span className="fa fa-brands fa-linkedin"></span></a></li>
								</ul>
							</div>

						</div>
					</div>

				</div>
			</footer>




		</div>
	)
}
export default Layout;